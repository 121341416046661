<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col lg="5">
        <iq-card class="p-4 top-box">
          <div class="d-flex justify-content-center">
            <curva-title :title="$t('login.login')" class="mb-2"/>
          </div>
          <b-row class="d-flex justify-content-center">
            <b-col lg="9" cols="11">
              <ValidationObserver v-slot="{ handleSubmit }">
                <b-form @submit.prevent="handleSubmit(login)" class="mb-4">
                <b-row>
                  <b-col lg="12" class="mb-2">
                    <input-form
                        v-model="loginInfo.email"
                        :placeholder="$t('login.email')"
                        :validate="'required'"
                        :name="$t('login.email')"
                    />
                  </b-col>
                  <b-col lg="12">
                    <input-form
                        v-model="loginInfo.password"
                        type="password"
                        :placeholder="$t('login.password')"
                        :validate="'required'"
                        :name="$t('login.password')"
                    />
                  </b-col>
                  <b-col lg="12" class="mb-2">
                    <div class="d-flex justify-content-end">
                      <router-link
                          :to="{name:'forget'}"><span class="text-warning">{{ $t('login.forgotPass') }}</span></router-link>
                    </div>
                  </b-col>
                  <b-col lg="12" class="mb-3">
                    <b-button variant="warning" type="submit" class="w-100" v-if="!loginLoading">
                      <span class="font-size-16">{{ $t('login.login') }}</span>
                    </b-button>
                    <b-button variant="warning" class="w-100" v-else>
                      <spinner-loading :text="$t('login.checking')" />
                    </b-button>
                  </b-col>
                  <b-col lg="12">
                    <div class="text-initial">
                      <span class=" text-gray mr-2">{{ $t('login.account') }}</span>
                      <router-link :to="{name:'registration'}"><span class="text-warning">{{ $t('login.create') }}</span></router-link>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
              </ValidationObserver>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center align-items-center">
            <b-col lg="4" cols="2">
              <span class="social_login_border"></span>
            </b-col>
            <b-col lg="4" cols="7">
              <div class="d-flex justify-content-center">
                <span class="text-gray">{{ $t('login.orLogin') }}</span>
              </div>
            </b-col>
            <b-col lg="4" cols="2">
              <span class="social_login_border" style="left: 0"></span>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-around mt-4 px-4 gap_2">
            <a href="https://api.curvaegypt.com/public/api/auth/socials/facebook/login">
            <button class="social_buttons facebook">
              <img :src="require('@/assets/images/curva/social/facebook.png')" alt="facebook"/>
              <span>{{ $t('login.facebook') }}</span>
            </button>
            </a>
            <a href="https://api.curvaegypt.com/public/api/auth/socials/google/login">
            <button class="social_buttons google">
              <img :src="require('@/assets/images/curva/social/google.png')" alt="Google"/>
              <span>{{ $t('login.google') }}</span>
            </button>
            </a>
<!--            <button class="social_buttons twitter">
              <img :src="require('@/assets/images/curva/social/twitter.png')" alt="Twitter"/>
              <span>{{ $t('login.twitter') }}</span>
            </button>-->
          </div>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import authServices from '../auth.services'
export default {
  data () {
    return {
      loginLoading: false,
      loginInfo: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    login () {
      this.loginLoading = true
      authServices.login(this.loginInfo).then(res => {
        core.showSnackbar('success', res.data.message)
        localStorage.setItem('userInfo', JSON.stringify(res.data.data))
        localStorage.setItem('userToken', res.data.data.access_token)
        this.$store.commit('loginStatus', true)
        this.$store.dispatch('storeCartCount')
        this.$store.dispatch('getCartContent')
        this.$router.push('/')
      }).catch(() => {
        core.showSnackbar('error', 'Failed to login')
      }).finally(() => {
        this.loginLoading = false
      })
    },
    loginSocial (provider) {
      authServices.loginSocial(provider).then(res => {
        console.log(res)
      })
    }
  },
  mounted () {
    core.index()
  }
}
</script>
<style>
@media(max-width:479px) {
  .social_login_border{
    width: 100%;
  }
}
</style>
