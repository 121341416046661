<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form ref="form" @submit.prevent="handleSubmit(addAddress)" class="curva-form mb-5 p-5">
        <b-row class="justify-content-center">
          <b-col lg="6" class="pt-3">
            <b-row>
              <b-col lg="12" class="mb-1">
                <main-select :placeholder="$t('forms.state')"
                             v-model="address.state_id"
                             :options="allStates"
                             label="name"
                             :reduce="item=>item.id"
                             :validate="'required'"
                              name="state"
                              class="curva-main-select"
                ></main-select>
              </b-col>

              <b-col lg="12" class="mb-1">
                <main-select :placeholder="$t('forms.city')"
                             v-model="address.city_id"
                             :options="allCities" label="name"
                             :reduce="item=>item.id"
                             :name="`city`"
                             :validate="'required'"
                             class="curva-main-select"
                ></main-select>
              </b-col>

              <b-col lg="12" class="mb-1">
                <main-select :placeholder="$t('forms.region')"
                             v-model="address.region_id"
                             :options="allRegions" label="name"
                             :reduce="item=>item.id"
                             :validate="'required'" :name="`region`"
                              class="curva-main-select"
                ></main-select>
              </b-col>

              <b-col lg="12" class="mb-1">
                <input-form :placeholder="$t('forms.street')" v-model="address.street"
                            :validate="'required'" :name="`Address`"/>
              </b-col>

              <b-col lg="12" class="mb-1">
                <input-form :placeholder="$t('forms.block_no')"
                            v-model="address.block_no"
                            :validate="'required|numeric'" :name="`block_no`"/>
              </b-col>

              <b-col lg="12" class="mb-1">
                <input-form :placeholder="$t('forms.floor_no')"
                            v-model="address.floor_no"
                            :validate="'required|numeric'" :name="`floor_no`"/>
              </b-col>

              <b-col lg="12" class="mb-1">
                <input-form :placeholder="$t('forms.flat_no')"
                            v-model="address.flat_no"
                            :validate="'required|numeric'"
                            :name="`flat_no`"
                          />
              </b-col>

              <b-col lg="12" class="mb-1">
                <input-form :placeholder="$t('forms.phone')" v-model="address.phone"
                            :validate="'required|numeric|digits:11'" :name="`Mobile number`"/>
              </b-col>
              <b-col lg="12" class="mb-5">
                <input-form :placeholder="$t('forms.phone2')" v-model="address.phone2"
                            :validate="'required|numeric|digits:11'" :name="`Second mobile number`"/>
              </b-col>
              <b-col lg="12" class="text-right d-flex align-items-center justify-content-start gap_2">
                <b-button class="grey-btn small-rounded-btn mx-3" @click="$router.push({name:'addressBook'})">{{
                    $t('forms.discard')
                  }}</b-button>
                <b-button class="curva-granola-btn small-rounded-btn" type="submit">{{ $t('forms.save') }}</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import ProfileServices from '@/modules/profile/services/profile.services'
import mainServices from '@/services/main'
export default {
  data () {
    return {
      address: {
        state_id: '',
        city_id: '',
        region_id: '',
        block_no: '',
        flat_no: '',
        floor_no: '',
        phone: '',
        phone2: '',
        street: '',
        additional_info: ''
      },
      allStates: [],
      allCities: [],
      allRegions: []
    }
  },
  methods: {
    addAddress () {
      if (this.$route.params.oldData) {
        ProfileServices.editAddress(this.$route.params.oldData.id, this.address).then(res => {
          core.showSnackbar(res.data.message)
          this.$router.push({ name: localStorage.getItem('lastPage') || 'addressBook' })
        })
      } else {
        console.log('add')
        ProfileServices.addAddress(this.address).then(res => {
          core.showSnackbar(res.data.message)
          this.$router.push({ name: localStorage.getItem('lastPage') || 'addressBook' })
        })
      }
    },
    discard () {
      this.$refs.form.reset()
    },
    isEng () {
      return localStorage.getItem('lang') === 'en'
    },
    getAllStates () {
      mainServices.getState().then(res => {
        this.allStates = res.data.data
      })
    },
    getAllCities (id) {
      mainServices.getCities(id).then(res => {
        this.allCities = res.data.data
      })
    },
    getAllRegion (id) {
      mainServices.getRegions(id).then(res => {
        this.allRegions = res.data.data
      })
    }
  },
  watch: {
    'address.state_id': function (newVal) {
      this.allCities = []
      this.allRegions = []
      this.getAllCities(newVal)
    },

    'address.city_id': function (newVal) {
      this.allRegions = []
      this.getAllRegion(newVal)
    }

  },
  created () {
    this.getAllStates()
    if (this.$route.params.type === 'edit') {
      const old = this.$route.params.oldData
      this.address = {
        state_id: old.state_id,
        city_id: old.city_id,
        region_id: old.region_id,
        block_no: old.block_no,
        flat_no: old.flat_no,
        floor_no: old.floor_no,
        phone: old.phone,
        phone2: old.phone2,
        street: old.street,
        additional_info: old.additional_info
      }
    }
  },
  mounted () {
    core.index()
  }
}
</script>
