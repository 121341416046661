import Api from '@/axios'
export default {
  getCategories () {
    return Api().get('pages/categories')
  },
  getCatProduct (obj) {
    return Api().get(`pages/categories/products/${obj.category}`, { params: obj })
  },
  getCategoryProducts (category, sort, page, type, brand = '', club = '', min = '', max = '') {
    return Api().get(`pages/categories/products/${category}?sort=${sort}&min=${min}&max=${max}&page=${page}&type=${type}${brand !== undefined ? `&brand=${brand}` : ''}${club !== undefined ? `&club=${club}` : ''}`)
  },
  getCategoryProductsByPrice (category, sort, page, min, max, type, brand = '', club = '') {
    return Api().get(`pages/categories/products/${category}?sort=${sort}&min=${min}&max=${max}&page=${page}&type=${type}&brand=${brand}&club=${club}`)
  }
}
