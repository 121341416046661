import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import './helper'
import './components'
import AlgoliaComponents from 'vue-instantsearch'
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
Vue.use(AlgoliaComponents)
Vue.use(VueFormWizard)
Vue.config.productionTip = false

const vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
// eslint-disable-next-line
// export default (context) => {
//   const app = new Vue({
//     router,
//     store,
//     render: (h) => h(App)
//   })
//
//   return { app, router, store }
// }
