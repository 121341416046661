<template>
  <iq-card class="indecatorMenu d-block d-lg-none">
    <div class="position-relative border_navigation">
    <ul class="d-flex justify-content-around p-0 m-0">
      <router-link :to="{name: 'index'}" exact tag="li"
                   class="d-flex flex-column justify-content-center align-items-center py-3 cursor-pointer">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_38_4210)">
            <path d="M23.1212 9.06892L15.5362 1.48292C14.5975 0.546909 13.3259 0.0212936 12.0002 0.0212936C10.6746 0.0212936 9.40298 0.546909 8.46423 1.48292L0.879226 9.06892C0.599651 9.3467 0.377996 9.67722 0.227108 10.0413C0.0762209 10.4054 -0.000896777 10.7958 0.000225703 11.1899V21.0069C0.000225703 21.8026 0.316296 22.5656 0.878905 23.1282C1.44151 23.6908 2.20458 24.0069 3.00023 24.0069H21.0002C21.7959 24.0069 22.5589 23.6908 23.1215 23.1282C23.6842 22.5656 24.0002 21.8026 24.0002 21.0069V11.1899C24.0014 10.7958 23.9242 10.4054 23.7733 10.0413C23.6225 9.67722 23.4008 9.3467 23.1212 9.06892ZM15.0002 22.0069H9.00023V18.0729C9.00023 17.2773 9.3163 16.5142 9.87891 15.9516C10.4415 15.389 11.2046 15.0729 12.0002 15.0729C12.7959 15.0729 13.5589 15.389 14.1215 15.9516C14.6842 16.5142 15.0002 17.2773 15.0002 18.0729V22.0069ZM22.0002 21.0069C22.0002 21.2721 21.8949 21.5265 21.7073 21.714C21.5198 21.9016 21.2654 22.0069 21.0002 22.0069H17.0002V18.0729C17.0002 16.7468 16.4734 15.4751 15.5358 14.5374C14.5981 13.5997 13.3263 13.0729 12.0002 13.0729C10.6741 13.0729 9.40238 13.5997 8.46469 14.5374C7.52701 15.4751 7.00023 16.7468 7.00023 18.0729V22.0069H3.00023C2.73501 22.0069 2.48066 21.9016 2.29312 21.714C2.10558 21.5265 2.00023 21.2721 2.00023 21.0069V11.1899C2.00115 10.9249 2.10642 10.6709 2.29323 10.4829L9.87823 2.89992C10.4419 2.33885 11.2049 2.02386 12.0002 2.02386C12.7956 2.02386 13.5585 2.33885 14.1222 2.89992L21.7072 10.4859C21.8933 10.6732 21.9985 10.9259 22.0002 11.1899V21.0069Z" fill="#374957"/>
          </g>
          <defs>
            <clipPath id="clip0_38_4210">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <span class="font-size-14">{{ $t('mainNav.home') }}</span>
      </router-link>
      <router-link to="/categories/all-categories" exact tag="li"
                   class="d-flex flex-column justify-content-center align-items-center py-3 cursor-pointer">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_38_4478)">
            <path d="M2 11H13C13.5304 11 14.0391 10.7893 14.4142 10.4142C14.7893 10.0391 15 9.53043 15 9V2C15 1.46957 14.7893 0.960859 14.4142 0.585786C14.0391 0.210714 13.5304 0 13 0L2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 9C0 9.53043 0.210714 10.0391 0.585786 10.4142C0.960859 10.7893 1.46957 11 2 11ZM2 2H13V9H2V2Z" fill="#374957"/>
            <path d="M22.0001 -7.62939e-06H19.0001C18.4697 -7.62939e-06 17.961 0.210706 17.5859 0.585779C17.2108 0.960852 17.0001 1.46956 17.0001 1.99999V8.99999C17.0001 9.53043 17.2108 10.0391 17.5859 10.4142C17.961 10.7893 18.4697 11 19.0001 11H22.0001C22.5306 11 23.0393 10.7893 23.4143 10.4142C23.7894 10.0391 24.0001 9.53043 24.0001 8.99999V1.99999C24.0001 1.46956 23.7894 0.960852 23.4143 0.585779C23.0393 0.210706 22.5306 -7.62939e-06 22.0001 -7.62939e-06V-7.62939e-06ZM22.0001 8.99999H19.0001V1.99999H22.0001V8.99999Z" fill="#374957"/>
            <path d="M5 13.0003H2C1.46957 13.0003 0.960859 13.211 0.585787 13.5861C0.210714 13.9612 0 14.4699 0 15.0003L0 22.0003C0 22.5307 0.210714 23.0394 0.585787 23.4145C0.960859 23.7896 1.46957 24.0003 2 24.0003H5C5.53043 24.0003 6.03914 23.7896 6.41421 23.4145C6.78929 23.0394 7 22.5307 7 22.0003V15.0003C7 14.4699 6.78929 13.9612 6.41421 13.5861C6.03914 13.211 5.53043 13.0003 5 13.0003ZM5 22.0003H2V15.0003H5V22.0003Z" fill="#374957"/>
            <path d="M22 13.0003H11C10.4696 13.0003 9.96086 13.211 9.58579 13.5861C9.21071 13.9612 9 14.4699 9 15.0003V22.0003C9 22.5307 9.21071 23.0394 9.58579 23.4145C9.96086 23.7896 10.4696 24.0003 11 24.0003H22C22.5304 24.0003 23.0391 23.7896 23.4142 23.4145C23.7893 23.0394 24 22.5307 24 22.0003V15.0003C24 14.4699 23.7893 13.9612 23.4142 13.5861C23.0391 13.211 22.5304 13.0003 22 13.0003ZM22 22.0003H11V15.0003H22V22.0003Z" fill="#374957"/>
          </g>
          <defs>
            <clipPath id="clip0_38_4478">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <span class="font-size-14">{{ $t('mainNav.categories') }}</span>
      </router-link>
<!--    Login required  -->
      <router-link v-if="ifLogin()" :to="{name: 'cart'}" exact tag="li"
                   class="d-flex flex-column justify-content-center align-items-center py-3 cursor-pointer">
        <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_721_3131)">
            <path d="M22.713 4.577C22.4317 4.23944 22.0796 3.96795 21.6815 3.78182C21.2835 3.59568 20.8494 3.49946 20.41 3.5H4.242L4.2 3.149C4.11405 2.41942 3.76338 1.74673 3.21449 1.25848C2.6656 0.770223 1.95663 0.500342 1.222 0.5L1 0.5C0.734784 0.5 0.48043 0.605357 0.292893 0.792893C0.105357 0.98043 0 1.23478 0 1.5C0 1.76522 0.105357 2.01957 0.292893 2.20711C0.48043 2.39464 0.734784 2.5 1 2.5H1.222C1.46693 2.50003 1.70334 2.58996 1.88637 2.75272C2.06941 2.91547 2.18634 3.13975 2.215 3.383L3.591 15.083C3.73385 16.2998 4.31848 17.4218 5.23391 18.236C6.14934 19.0502 7.33185 19.5 8.557 19.5H19C19.2652 19.5 19.5196 19.3946 19.7071 19.2071C19.8946 19.0196 20 18.7652 20 18.5C20 18.2348 19.8946 17.9804 19.7071 17.7929C19.5196 17.6054 19.2652 17.5 19 17.5H8.557C7.93806 17.4983 7.3348 17.3051 6.82994 16.9471C6.32507 16.589 5.94331 16.0835 5.737 15.5H17.657C18.8293 15.5001 19.9643 15.0882 20.8638 14.3364C21.7633 13.5846 22.37 12.5407 22.578 11.387L23.363 7.033C23.4414 6.60101 23.4237 6.15707 23.3114 5.73264C23.1991 5.30821 22.9948 4.91368 22.713 4.577ZM21.4 6.678L20.614 11.032C20.4891 11.725 20.1245 12.352 19.5839 12.8032C19.0433 13.2544 18.3612 13.5011 17.657 13.5H5.419L4.478 5.5H20.41C20.5569 5.49912 20.7022 5.53062 20.8355 5.59226C20.9689 5.65389 21.087 5.74415 21.1815 5.85661C21.276 5.96907 21.3446 6.10097 21.3824 6.24294C21.4201 6.3849 21.4262 6.53344 21.4 6.678Z" fill="white"/>
            <path d="M7.00012 24.5007C8.10469 24.5007 9.00011 23.6053 9.00011 22.5007C9.00011 21.3962 8.10469 20.5007 7.00012 20.5007C5.89555 20.5007 5.00012 21.3962 5.00012 22.5007C5.00012 23.6053 5.89555 24.5007 7.00012 24.5007Z" fill="white"/>
            <path d="M17 24.5007C18.1046 24.5007 19 23.6053 19 22.5007C19 21.3962 18.1046 20.5007 17 20.5007C15.8954 20.5007 15 21.3962 15 22.5007C15 23.6053 15.8954 24.5007 17 24.5007Z" fill="white"/>
          </g>
          <defs>
            <clipPath id="clip0_721_3131">
              <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
          </defs>
        </svg>
        <span class="font-size-14">{{ $t('cart.cart') }}</span>
      </router-link>
      <router-link v-if="ifLogin()" :to="{name: 'profileInfo'}" exact tag="li"
                   class="d-flex flex-column justify-content-center align-items-center py-3 cursor-pointer">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_38_4022)">
            <path d="M12 11.9999C13.1867 11.9999 14.3467 11.648 15.3334 10.9888C16.3201 10.3295 17.0892 9.3924 17.5433 8.29604C17.9974 7.19969 18.1162 5.99329 17.8847 4.8294C17.6532 3.66551 17.0818 2.59642 16.2426 1.7573C15.4035 0.918186 14.3344 0.346741 13.1705 0.11523C12.0067 -0.116281 10.8003 0.00253868 9.7039 0.456664C8.60754 0.91079 7.67047 1.67983 7.01118 2.66652C6.35189 3.65321 6 4.81325 6 5.99994C6.00159 7.59075 6.63424 9.11595 7.75911 10.2408C8.88399 11.3657 10.4092 11.9984 12 11.9999ZM12 1.99994C12.7911 1.99994 13.5645 2.23454 14.2223 2.67406C14.8801 3.11359 15.3928 3.7383 15.6955 4.46921C15.9983 5.20011 16.0775 6.00438 15.9231 6.7803C15.7688 7.55623 15.3878 8.26896 14.8284 8.82837C14.269 9.38778 13.5563 9.76874 12.7804 9.92308C12.0044 10.0774 11.2002 9.99821 10.4693 9.69546C9.73836 9.39271 9.11365 8.88002 8.67412 8.22222C8.2346 7.56443 8 6.79107 8 5.99994C8 4.93908 8.42143 3.92166 9.17157 3.17151C9.92172 2.42137 10.9391 1.99994 12 1.99994Z" fill="#374957"/>
            <path d="M12 14.0006C9.61386 14.0033 7.32622 14.9523 5.63896 16.6396C3.95171 18.3268 3.00265 20.6145 3 23.0006C3 23.2658 3.10536 23.5202 3.29289 23.7077C3.48043 23.8953 3.73478 24.0006 4 24.0006C4.26522 24.0006 4.51957 23.8953 4.70711 23.7077C4.89464 23.5202 5 23.2658 5 23.0006C5 21.1441 5.7375 19.3636 7.05025 18.0509C8.36301 16.7381 10.1435 16.0006 12 16.0006C13.8565 16.0006 15.637 16.7381 16.9497 18.0509C18.2625 19.3636 19 21.1441 19 23.0006C19 23.2658 19.1054 23.5202 19.2929 23.7077C19.4804 23.8953 19.7348 24.0006 20 24.0006C20.2652 24.0006 20.5196 23.8953 20.7071 23.7077C20.8946 23.5202 21 23.2658 21 23.0006C20.9974 20.6145 20.0483 18.3268 18.361 16.6396C16.6738 14.9523 14.3861 14.0033 12 14.0006Z" fill="#374957"/>
          </g>
          <defs>
            <clipPath id="clip0_38_4022">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <span class="font-size-14">{{ $t('profile.profile') }}</span>
      </router-link>
      <router-link v-else :to="{name: 'login'}" exact tag="li"
                   class="d-flex flex-column justify-content-center align-items-center py-3 cursor-pointer">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_38_4022)">
            <path d="M12 11.9999C13.1867 11.9999 14.3467 11.648 15.3334 10.9888C16.3201 10.3295 17.0892 9.3924 17.5433 8.29604C17.9974 7.19969 18.1162 5.99329 17.8847 4.8294C17.6532 3.66551 17.0818 2.59642 16.2426 1.7573C15.4035 0.918186 14.3344 0.346741 13.1705 0.11523C12.0067 -0.116281 10.8003 0.00253868 9.7039 0.456664C8.60754 0.91079 7.67047 1.67983 7.01118 2.66652C6.35189 3.65321 6 4.81325 6 5.99994C6.00159 7.59075 6.63424 9.11595 7.75911 10.2408C8.88399 11.3657 10.4092 11.9984 12 11.9999ZM12 1.99994C12.7911 1.99994 13.5645 2.23454 14.2223 2.67406C14.8801 3.11359 15.3928 3.7383 15.6955 4.46921C15.9983 5.20011 16.0775 6.00438 15.9231 6.7803C15.7688 7.55623 15.3878 8.26896 14.8284 8.82837C14.269 9.38778 13.5563 9.76874 12.7804 9.92308C12.0044 10.0774 11.2002 9.99821 10.4693 9.69546C9.73836 9.39271 9.11365 8.88002 8.67412 8.22222C8.2346 7.56443 8 6.79107 8 5.99994C8 4.93908 8.42143 3.92166 9.17157 3.17151C9.92172 2.42137 10.9391 1.99994 12 1.99994Z" fill="#374957"/>
            <path d="M12 14.0006C9.61386 14.0033 7.32622 14.9523 5.63896 16.6396C3.95171 18.3268 3.00265 20.6145 3 23.0006C3 23.2658 3.10536 23.5202 3.29289 23.7077C3.48043 23.8953 3.73478 24.0006 4 24.0006C4.26522 24.0006 4.51957 23.8953 4.70711 23.7077C4.89464 23.5202 5 23.2658 5 23.0006C5 21.1441 5.7375 19.3636 7.05025 18.0509C8.36301 16.7381 10.1435 16.0006 12 16.0006C13.8565 16.0006 15.637 16.7381 16.9497 18.0509C18.2625 19.3636 19 21.1441 19 23.0006C19 23.2658 19.1054 23.5202 19.2929 23.7077C19.4804 23.8953 19.7348 24.0006 20 24.0006C20.2652 24.0006 20.5196 23.8953 20.7071 23.7077C20.8946 23.5202 21 23.2658 21 23.0006C20.9974 20.6145 20.0483 18.3268 18.361 16.6396C16.6738 14.9523 14.3861 14.0033 12 14.0006Z" fill="#374957"/>
          </g>
          <defs>
            <clipPath id="clip0_38_4022">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <span class="font-size-14">{{ $t('login.login') }}</span>
      </router-link>
    </ul>
    </div>
  </iq-card>
</template>
<script>
export default {
  methods: {
    ifLogin () {
      return localStorage.getItem('userToken') ? localStorage.getItem('userToken') : this.$store.getters.getLoginStatus
    }
  }
}
</script>
