import Api from '@/axios'

export default {
  getCart (page = 1) {
    return Api().get('users/carts')
  },
  addCart (id, payload) {
    return Api().post(`users/carts/${id}`, payload)
  },
  removeCart (id) {
    return Api().delete(`users/carts/${id}`)
  },
  changeQuantity (id, payload) {
    return Api().post(`users/carts/quantity/${id}`, payload)
  },
  placeOrder (payload) {
    return Api().post('users/orders/cart', payload)
  }
}
