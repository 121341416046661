<template>
  <b-container class="pt-4">
    <b-row class="mb-4">
      <b-col class="d-flex justify-content-between">
        <curva-title :title="$t('mainNav.complaints')"/>
      </b-col>
    </b-row>
    <b-row><b-col>
      <iq-card class="details-container iq-style3 blog p-0">
        <b-row class="p-0">
          <b-col lg="7" md="12" class="text-center p-5">
            <h4 class="text-warning text-initial">{{ $t('complaints.readCarefully') }}</h4>
            <p class="text-initial" v-html="$t('complaints.complaintsText')"></p>
          </b-col>
          <b-col lg="5" md="12" class="iq-style4 p-5">
            <ValidationObserver v-slot="{ handleSubmit }">
              <b-form
                  ref="form"
                  @submit.prevent="handleSubmit(sendMsg)"
                  class="curva-form curva-form-up mb-5"
              >
                <b-row class="mb-3">
                </b-row>
                <b-row>
                  <b-col lg="12" class="mb-1">
                    <main-select
                        :placeholder="$t('complaints.msgType')"
                        :options="[]"
                        :validate="'required'"
                        :name="`msg type`"
                        custom-class="curva-main-select"
                        label="key" :reduce="(data) => data.value">
                    </main-select>
                  </b-col>
                  <b-col lg="12" class="mb-1">
                    <input-form
                        :placeholder="$t('complaints.title')"
                        :validate="'required'"
                        :name="`title`"
                        custom-class="curva-control-input"
                    />
                  </b-col>
                  <b-col lg="12" class="mb-1">
                    <b-form-group label-for="Message">
                      <ValidationProvider
                          name="FMessage"
                          ref="Message"
                          rules="required"
                          v-slot="{ errors }"
                      >
                        <b-form-textarea
                            :placeholder="$t('forms.msg')+'..'"
                            rows="2"
                            :validate="'required'"
                            :class="[
                            'curva-textarea',
                            errors.length > 0 ? ' is-invalid' : '',
                          ]"
                        ></b-form-textarea>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" class="mb-3">
                    <b-form-file
                        :placeholder="$t('forms.dropFile')"
                        drop-placeholder="Drop file here..."
                        class="form-control"
                    ></b-form-file>
                  </b-col>
                  <b-col lg="12">
                    <b-button
                        class="curva-granola-btn small-rounded-btn"
                        type="submit"
                        block
                    >{{ $t('forms.send') }}</b-button
                    >
                  </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </b-col>
        </b-row>
      </iq-card>
    </b-col></b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
export default {
  created () {
  },
  mounted () {
    core.index()
  }
}
</script>
