import Api from '@/axios'

export default {
  getSeacrhList (value, page, sort) {
    return Api().get(`/pages/categories/products?title=${value}&sort=${sort}&page=${page}`)
  },
  searchProducts (filter) {
    return Api().get('products', {
      params: filter
    })
  }
}
