import Api from '@/axios'

export default {
  getProfileData () {
    return Api().get('users/profile')
  },
  editProfile (payload) {
    return Api().post('users/profile', payload)
  },
  getOrders () {
    return Api().get('users/orders')
  },
  getOrderDetails (id) {
    return Api().get(`users/orders/${id}`)
  },
  getAddress () {
    return Api().get('users/profile/address')
  },
  addAddress (payload) {
    return Api().post('users/profile/store/address', payload)
  },
  editAddress (id, payload) {
    return Api().post(`users/profile/update/address/${id}`, payload)
  },
  getWishlist (page) {
    return Api().get(`users/wishlists?page=${page}`)
  },
  addToWishlist (id) {
    return Api().post(`users/wishlists/${id}`)
  },
  removeFromWishlist (id) {
    return Api().delete(`users/wishlists/${id}`)
  },
  changePassword (payload) {
    return Api().post('users/profile/change-password', payload)
  },
  deleteAddress (id) {
    return Api().delete(`users/profile/delete/address/${id}`)
  }
}
