<template>
  <div class="curva-list iq-style4 p-4 pl-5 mb-5">
    <b-row>
      <b-col lg="3" class="d-flex justify-content-center">
        <img class="circle-img" :src="info.image" @error="replaceByDefault">
      </b-col>
      <b-col lg="9">
        <b-row class="mb-3">
          <h4 class="text-warning info-name mx-2">{{info.fullname}}</h4>
          <img style="height: 21px; width: 21px;" :src="info.gender === 'male'? maleImg : femaleImg">
        </b-row>
        <b-row>
          <b-col lg="12"><p class="text-initial"><i class="text-warning las la-envelope mr-2"></i>{{ info.Email }}</p></b-col>
          <b-col lg="12"><p class="text-initial"><i class="text-warning las la-phone mr-2"></i>{{ info.mobile }}</p></b-col>
          <b-col lg="12"><p class="text-initial"><i
              class="text-warning las la-birthday-cake mr-2"></i>{{ info.B_date }}</p></b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import ProfileServices from '@/modules/profile/services/profile.services'
export default {
  data () {
    return {
      info: {},
      maleImg: require('@/assets/images/curva/male.png'),
      femaleImg: require('@/assets/images/curva/female.png'),
      defaultImage: require('@/assets/images/user/default-user-image.png')
    }
  },
  methods: {
    getProfileData () {
      ProfileServices.getProfileData().then(res => {
        this.info = res.data.data
        console.log(this.info)
      })
    },
    replaceByDefault (e) {
      e.target.src = this.defaultImage
    }
  },
  created () {
    this.getProfileData()
  },
  mounted () {
    core.index()
  }
}
</script>
