import CartServices from '@/modules/cart/services/cart.services'
export default {
  state: {
    totalSummary: {
      totalItem: 0,
      subtotal: 0,
      tax: 0,
      total: 0
    },
    deliveryFees: 0,
    cart: [],
    loginStatus: false,
    order: {
      address: '',
      notes: '',
      terms: false
    },
    cartStep: 1
  },
  mutations: {
    loginStatus (state, payload) {
      state.loginStatus = payload
    },
    addToCart (state, payload) {
      console.log(payload)
      CartServices.addCart(payload.productId, { size: payload.size.size, quantity: payload.quantity }).then(res => {
        // state.cart.push(payload)
      })
    },
    removeItemFromCart (state, id) {
      CartServices.removeCart(id).then(res => {
        var ind = state.cart.findIndex(data => data.id === id)
        state.cart.splice(ind, 1)
      })
    },
    cartStep (state, payload) {
      state.cartStep = payload
    },
    storeCart (state, payload) {
      state.cart = payload
    },
    setAddress (state, payload) {
      state.order.address = payload
    },
    setNotes (state, payload) {
      state.order.notes = payload
    },
    setTerms (state, payload) {
      state.order.terms = payload
    },
    updateQuantity (state, payload) {
      state.cart[state.cart.findIndex(item => item.id === payload.id)].quantity = payload.quantity
    },
    updateDeliveryFees (state, payload) {
      state.deliveryFees = payload
    }
  },
  getters: {
    getLoginStatus: state => state.loginStatus,
    getCart: state => state.cart,
    getCartCount: state => state.cart.length,
    getCartStep: state => state.cartStep,
    getOrder: state => state.order,
    getNotes: state => state.order.notes,
    getTerms: state => state.order.terms,
    getDeliveryFees: state => state.deliveryFees,
    getSummery: state => {
      const summery = {
        totalItem: 0,
        subtotal: 0,
        tax: 0,
        total: 0
      }
      if (state.cart.length > 0) {
        for (const item in state.cart) {
          summery.totalItem += Number(state.cart[item].quantity)
          console.log(state.cart[item].total_price)
          summery.subtotal += Number(state.cart[item].total_price)
          summery.tax = 0
        }
      } else {
        console.log(' not array')
      }
      summery.total = summery.subtotal + summery.tax
      return summery
    }
  },
  actions: {
    getCartContent (context) {
      CartServices.getCart().then(res => {
        context.commit('storeCart', res.data.data)
      })
    },
    storeCartCount ({
      context,
      state
    }) {
      console.log(state)
      if (state.cart.length < 1) {
        console.log(state.cart)
        CartServices.getCart().then(res => {
          console.log(res.data.data)
          context.commit('storeCart', res.data.data)
        })
      }
    }
  }
}
