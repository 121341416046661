<template>
  <div>
    <home-carousel :banners="homeData.banners"/>
    <explore-categories />
    <arrivals-section :arrivals="homeData.arrivals"/>
    <best-seller :bestSeller="homeData.best_seller"/>
    <top-brands :topBrands="homeData.brands"/>
    <top-clubs :topClubs="homeData.clubs"/>
    <our-blogs :blogs="homeData.blogs"/>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import HomeCarousel from '@/modules/index/components/home-carousel'
import ExploreCategories from '@/modules/index/components/explore-categories'
import ArrivalsSection from '@/modules/index/components/arrivals-section'
import BestSeller from '@/modules/index/components/best-seller'
import TopBrands from '@/modules/index/components/top-brands'
import TopClubs from '@/modules/index/components/top-clubs'
import OurBlogs from '@/modules/index/components/our-blogs'
import HomeServices from '@/modules/index/services/index.services'
export default {
  name: 'index',
  data () {
    return {
      homeData: {
        banners: [],
        static_banners: [],
        categories: [],
        arrivals: [],
        best_seller: [],
        blogs: [],
        brands: [],
        clubs: [],
        top_items: []
      }
    }
  },
  components: { OurBlogs, TopClubs, TopBrands, BestSeller, ArrivalsSection, ExploreCategories, HomeCarousel },
  methods: {
    getHomeData () {
      HomeServices.getHomeData().then(res => {
        console.log(res)
        this.homeData = res.data.data
      })
    }
  },
  created () {
    // setTimeout(this.getHomeData(), 1)
  },
  mounted () {
    this.getHomeData()
    core.index()
  }
}
</script>
<!--
<style>
@media (max-width: 500px) {
  .img-container {
    height: 150px !important;
  }
}
</style>
-->
