<template>
  <div>
    <section class="iq-style1 py-4">
      <b-container>
        <b-row class="text-start mb-2">
          <b-col><curva-title :title="$t('productDetails.suggestProduct')" class="mb-5"></curva-title></b-col>
        </b-row>
        <div class="d-flex flex-lg-row flex-column justify-content-between align-items-center">
          <div class="item-search mb-1 mb-lg-0">
            <input v-model="suggestProduct.pricefrom" type="number" class="form-control w-100 mb-0"
                   :placeholder="$t('search.priceFrom')"/>
          </div>
          <div class="item-search mb-1 mb-lg-0">
          <input v-model="suggestProduct.priceto" type="number" class="form-control w-100 mb-0"
                 :placeholder="$t('search.priceTo')"/>
          </div>
          <div class="item-search mb-1 mb-lg-0">
          <main-select  class="w-100 mb-0" v-model="suggestProduct.cat" :placeholder="$t('search.category')"
                       label="name" :reduce="data => data.id" :name="'categories'"
                       :options="categories" @change="getDependSubCategory">
          </main-select>
          </div>
          <div class="item-search mb-1 mb-lg-0">
          <main-select class="w-100 mb-0" v-model="suggestProduct.sub" :placeholder="$t('search.sub')"
                       label="subcat" :reduce="data => data.id" :name="'sub-categories'"
                       :options="subCategories">
          </main-select>
          </div>
          <div class="item-search mb-1 mb-lg-0">
          <main-select class="w-100 mb-0" v-model="suggestProduct.size" :placeholder="$t('search.size')"
                       :options="[...sizeNumbers, ...sizeText]"></main-select>
          </div>
          <div class="item-search mb-1 mb-lg-0">
          <main-select class="w-100 mb-0" v-model="suggestProduct.branch" :placeholder="$t('search.branches')"
                       :options="branches" label="name" :reduce="data => data.id" :name="'branches'"></main-select>
          </div>
          <div class="item-search mb-1 mb-lg-0">
            <b-button class="curva-granola-btn w-100 mb-0" block @click="getProductByCode">
              {{ $t('search.search') }}
            </b-button>
          </div>
        </div>
      </b-container>
    </section>
    <b-container class="pt-4" >
      <div v-if="!loading">
      <b-row v-if="products">
        <b-col lg="3" v-for="(product, key) in products" :key="key">

<!--          <router-link :to="{name: 'productDetails', params: {id: product.code}}">-->
            <b-card
                class="product-card-container mb-5 cursor-pointer"
            >
              <div class="img-container  d-flex justify-content-center align-content-center" :style="{ 'background-image': 'url('+
               product.img + ')' }">
<!--                <img :src="product.img" :alt="product.name"/>-->
              </div>
              <b-card-body class="p-3 text-initial">
                <h4 style="white-space: nowrap; overflow: hidden;">{{ product.name }}</h4>
                <p  class="font-size-18"><span class="font-weight-bold text-warning">
                      {{product.price}} </span>
                  <span class="font-size-12"> {{$t('egp')}}</span></p>
                <b-row class="mb-3">
                  <b-col cols="4" class="text-gray">branch</b-col>
                  <b-col cols="8">{{product.branch}}</b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="4" class="text-gray">Available</b-col>
                  <b-col cols="8" >{{product.qty}}</b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="4" class="text-gray">Code</b-col>
                  <b-col cols="8">{{product.code}}</b-col>
                </b-row>
              </b-card-body>
            </b-card>
<!--          </router-link>-->
        </b-col>
      </b-row>
        <p v-else class="text-center mt-4">
          {{ $t('empty') }}
      </p>
      </div>
      <div v-else class="text-center">
        <spinner-loading :text="'loading'"></spinner-loading>
      </div>
    </b-container>

  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
// import axios from 'axios'
import searchServices from '../services/search.services'
import CategoriesServices from '@/modules/categories/services/categories.services'
import ContactServices from '@/modules/contact-us/services/contact-us.services'
export default {
  data () {
    return {
      products: null,
      sizeNumbers: ['10', '12', '14', '16', '18', '20', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48'],
      sizeText: ['5XS', '4XS', '3XS', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL'],
      // suggest popup
      categories: [],
      subCategories: [],
      branches: [],
      loading: false,
      suggestProduct: {
        pricefrom: '',
        priceto: '',
        cat: '',
        sub: '',
        size: '',
        branch: ''
      }
    }
  },
  methods: {
    getProductByCode () {
      this.loading = true
      searchServices.searchProducts(this.suggestProduct).then((res) => {
        this.products = res.data
        this.loading = false
      })
    },
    getAllCategories () {
      CategoriesServices.getCategories().then(res => {
        this.categories = res.data.data
      })
    },
    getDependSubCategory () {
      this.suggestProduct.sub = ''
      this.subCategories = this.categories.find(category => category.id === this.suggestProduct.cat).sub_category
    },
    getAllBranches () {
      ContactServices.getContactInfo().then((res) => {
        this.branches = res.data.data.branches
      })
    }
  },
  created () {
    // this.getProductByCode()
    this.getAllCategories()
    this.getAllBranches()
  },
  mounted () {
    core.index()
  }
}
</script>
<style>
.img-container {
  background-position: center;
}
.item-search {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  text-align: center;
  padding: 0 5px;
  width: 100%;
}
</style>
