import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
/* const ComingSoon = () => import('../views/Pages/ComingSoon') */
import index from '@/modules/index/home.routes'
import offers from '@/modules/offers/offers.routes'
import blogs from '@/modules/blogs/blogs.routes'
import contactUs from '@/modules/contact-us/contact-us.routes'
import social from '@/modules/curva-social/curva-social.routes'
import cart from '@/modules/cart/cart.routes'
import categories from '@/modules/categories/categories.routes'
import multimedia from '@/modules/multimedia/multimedia.routes'
import auth from '@/modules/auth/auth.routes'
import about from '@/modules/about/about-routes'
import terms from '@/modules/terms/terms.routes'
import products from '@/modules/all-products/allProducts.routes'
import profile from '@/modules/profile/profile.routes'
import search from '@/modules/search/search.routes'
/* Extra Pages */
const ErrorPage = () => import('../views/Pages/ErrorPage')

Vue.use(VueRouter)
Vue.use(VueMeta)
const routes = [
  ...index,
  ...offers,
  ...blogs,
  ...contactUs,
  ...social,
  ...cart,
  ...categories,
  ...multimedia,
  ...about,
  ...auth,
  ...terms,
  ...products,
  ...profile,
  ...search,
  {
    path: '*',
    name: 'errorPage',
    meta: { name: 'dashboard', userType: 'both' },
    component: ErrorPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
/* router.beforeEach((to, from, next) => {
  if (to.meta.userType === 'both') {
    next()
  } else if (securityFunctions.methods.isAdmin(to.meta.userType)) {
    if (securityFunctions.methods.hasPer(to.meta.permission)) {
      next()
    } else {
      debugger
      next({ name: 'errorPage' })
    }
  } else {
    if (securityFunctions.methods.hasServiceType(to.meta.serviceTypes) || to.meta.serviceTypes === 'all') {
      next()
    } else {
      debugger
      next({ name: 'errorPage' })
    }
  }
}) */
export default router
