<template>
  <div>
    <b-container>
      <div class="d-flex justify-content-center my-2"> <img :src="check" class="giftImage" alt="success"/> </div>
      <h2 class="text-center text-warning my-3" v-html="$t('main.congratulationsParagraph')"></h2>
      <b-row class="d-flex justify-content-between">
        <b-col lg="6">
          <p class="text-justify font-pre-line"><span>{{ $t('main.ordernumber') }}</span> <span
              class="text-warning">{{details.id}}</span> </p>
          <p class="text-justify font-pre-line" v-html="$t('main.congratulationsText')"></p>
        </b-col>
<!--        {{details}}-->
        <b-col lg="4">
          <!-- {{details}} -->
          <div class="order-summary">
            <p class="title text-center">{{ $t('cart.summary') }}</p>
            <b-row>
              <b-col class="text-start">{{ $t('cart.tItems') }}</b-col>
              <b-col class="text-end">
                {{details.products.length}}
              </b-col>
            </b-row>
             <b-row>
              <b-col  class="text-start">{{ $t('cart.subtotal') }}</b-col>
              <b-col class="text-end">
                <span>{{details.total_price - details.shipping}}
                  <span class="currency">{{$t('egp')}}</span>
                </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-start">{{ $t('cart.tax') }}</b-col>
              <b-col class="text-end">
                0<span class="currency">{{$t('egp') }}</span>
              </b-col>
            </b-row>
           <b-row>
              <b-col class="text-start">{{ $t('cart.fees') }}</b-col>
              <b-col class="text-end">
                <span>{{details.shipping||0}}
                  <span class="currency">{{$t('egp')}}</span></span>
              </b-col>
            </b-row>
            <b-row class="total-row">
              <b-col class="text-start">{{ $t('cart.total') }}</b-col>
              <b-col class="text-end">{{details.total_price}}
                <span class="currency">{{$t('egp')}}</span>
              </b-col>
            </b-row>
          </div>
        </b-col>
         <b-col lg="12" v-if="details">
          <!-- {{ details.products }} -->
          <b-table responsive show-empty
                   :items="details.products" :fields="tableHeader" primary-key="id"
                   class="curva-table" tbody-tr-class="curva-table-row">
            <template #cell(productName)="data">
              <router-link :to="{name: 'productDetails', params: {id: data.item.info.product.id}}">
                <img :src="data.item.info.product.image" class="table-img mr-2">
                {{data.item.info.product.name}}
              </router-link>
            </template>
           <template #cell(size)="data">
             {{data.item.info.size.name ||  '-'}}
           </template>
           <template #cell(color)="data">
            <span class="products_color_inside d-inline-block"
                       :style="{'background-color': data.item.info.color.color }"></span>
          </template>
          </b-table>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center my-4">
        <router-link :to="{ name: 'index' }">
        <b-button variant="warning" class="px-5 iq-border-radius-5 font-size-22">{{$t('mainNav.home')}} </b-button>
        </router-link>
      </div>
    </b-container>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import profileServices from '../../profile/services/profile.services'
export default {
  data () {
    return {
      check: require('@/assets/images/curva/giftbox.png'),
      tableHeader: [
        { key: 'productName', label: this.$t('cart.name'), class: 'px-3 text-initial' },
        { key: 'size', label: this.$t('cart.size'), class: 'px-3 text-center' },
        { key: 'color', label: this.$t('color'), class: 'px-3 text-center' },
        { key: 'uintprice', label: this.$t('cart.unitPrice'), class: 'px-3 text-center' },
        { key: 'quantity', label: this.$t('cart.quantity'), class: 'px-3 text-center' }
      ],
      details: {}
    }
  },
  computed: {
  },
  // props: ['details'],
  methods: {
    getOrderDetails () {
      profileServices.getOrderDetails(this.$route.params.id).then(res => {
        console.log(res.data.data)
        this.details = res.data.data
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.getOrderDetails()
    // if (!this.details) {
    //   this.$router.push({ name: 'index' })
    // }
  }
}
</script>
<style>
.giftImage {
  width: 80px;
}
.font-pre-line {
  white-space: break-spaces !important;
}
</style>
