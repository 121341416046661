import Api from '@/axios'

export default {
  getAbout () {
    return Api().get('pages/abouts')
  },
  getAllClubs (page = '1') {
    return Api().get(`pages/clubs?page=${page}&limit=1000`)
  },
  getSpacificClub (id) {
    return Api().get(`pages/clubs/${id}`)
  },
  getAllBrands (page = '1') {
    return Api().get(`pages/brands?page=${page}&limit=1000`)
  },
  getSpacificBrand (id) {
    return Api().get(`pages/brands/${id}`)
  },
  getAllTopProducts (page) {
    return Api().get(`pages/products?&page=${page}`)
  }
}
